import { TimeRange, summarizeAvailability } from 'shared/scheduling';

export type Props = {
  label: string;
  isDefault?: boolean;
  scheduleTimeZone: string;
  renderTimeZone: string;
  availability: Record<number, TimeRange[]>;
};

export function AvailableBlock(props: Props) {
  const availability = summarizeAvailability({
    availability: props.availability,
    hour12: true,
    scheduleTimeZone: props.scheduleTimeZone,
    renderTimeZone: props.renderTimeZone,
  });
  return (
    <span class="flex flex-col sm:flex-row sm:gap-4 justify-between font-semibold">
      <span class="flex items-center gap-2">
        <span>{props.label}</span>
        {props.isDefault && (
          <span class="text-xs px-1 bg-indigo-50 text-indigo-600 border border-indigo-300 rounded">
            Default
          </span>
        )}
      </span>
      <span class="flex flex-col gap-2 font-medium">
        {availability.map((x) => (
          <span key={`${x.days}-${x.times}`} class="flex gap-2">
            <span class="opacity-75">{x.days}</span> <span>{x.times}</span>
          </span>
        ))}
      </span>
    </span>
  );
}
