import type { ComponentChildren, h } from 'preact';
import {
  IcoAcademicCap,
  IcoBook,
  IcoChartBar,
  IcoFolder,
  IcoHome,
  IcoList,
  IcoMagnifyingGlass,
  IcoPercentBadge,
  IcoRuzukuLogo,
  IcoSearch,
  SVGProps,
} from '@components/icons';
import { BtnPrimary, Button } from '@components/buttons';
import { useState } from 'preact/hooks';
import { SlideOver } from '@components/slide-over';
import { UserMenu } from '@components/nav/user-menu';
import { showUpgradeToProModal } from '@components/upgrade-to-pro-modal';
import { useRootPageSearch } from './root-search';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { LoadedProps } from 'client/lib/loaders';
import { AppRoute } from 'client/lib/app-route/types';

type HomepageTabs = 'welcome' | 'overview' | 'courses' | 'products' | 'bundles' | 'upsells';
type CourseItems = RpxResponse<typeof rpx.courses.getMyCourses>;

export async function loadRootGuidePage(route: AppRoute) {
  const [courseData, upsells] = await Promise.all([
    rpx.courses.getMyCourses({
      asGuide: true,
    }),
    route.auth.user?.tier === 'pro' ? rpx.upsells.getMyUpsells() : Promise.resolve([]),
  ]);

  const data = courseData
    .sort((a, b) => b.lastOpenedAt.getTime() - a.lastOpenedAt.getTime())
    .reduce(
      (acc, item) => {
        if (item.isBundle) {
          acc.bundles.push(item);
        } else if (item.isProduct) {
          acc.products.push(item);
        } else {
          acc.courses.push(item);
        }
        return acc;
      },
      {
        courses: [],
        bundles: [],
        products: [],
      } as {
        courses: CourseItems;
        bundles: CourseItems;
        products: CourseItems;
      },
    );

  return {
    ...data,
    upsells,
  };
}

function UpgradeToProWidget() {
  return (
    <div class="bg-gray-800 rounded-3xl p-4 text-white text-center relative">
      <div class="absolute flex items-center justify-center inset-x-1/2 -ml-8 -top-6 w-16 h-16 bg-gray-800 rounded-full p-2">
        <div class="flex items-center justify-center rounded-full bg-white text-indigo-600">
          <IcoPercentBadge class="size-12" />
        </div>
      </div>
      <div class="mt-4">
        <span class="text-base">Fall Promo</span>
        <span class="block text-xs">Upgrade now and save 1000$/year</span>
        <BtnPrimary class="mt-4 rounded-full w-full" onClick={() => showUpgradeToProModal()}>
          Get Pro Now
        </BtnPrimary>
      </div>
    </div>
  );
}
function NavLink(props: {
  isSelected: boolean;
  href: string;
  name: string;
  Icon(p: SVGProps): h.JSX.Element;
}) {
  return (
    <li class="relative">
      {props.isSelected && (
        <span class="absolute inset-y-0 -left-2 w-[3px] rounded-tr-xl rounded-br-xl bg-indigo-600"></span>
      )}
      <a
        href={props.href}
        class={`group flex gap-x-3 rounded-md px-4 py-2 text-sm/6 ${
          props.isSelected
            ? 'text-indigo-600 font-semibold'
            : 'text-gray-800 hover:bg-gray-50 hover:text-indigo-600'
        }`}
      >
        <props.Icon
          aria-hidden="true"
          class={`h-5 w-5 shrink-0 ${
            props.isSelected ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600'
          }`}
        />
        {props.name}
      </a>
    </li>
  );
}

function NavBarContent({ activeTab, hidePromo }: { activeTab: HomepageTabs; hidePromo?: boolean }) {
  return (
    <div class="flex h-full min-h-0 flex-col">
      <div class="flex h-16 shrink-0 items-center p-4">
        <IcoRuzukuLogo class="h-6" />
      </div>
      <nav class="flex flex-1 flex-col p-4">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <NavLink
                isSelected={activeTab === 'welcome'}
                href="/welcome"
                name="Welcome"
                Icon={IcoHome}
              />
              <NavLink
                isSelected={activeTab === 'overview'}
                href="/overview"
                name="Overview"
                Icon={IcoMagnifyingGlass}
              />
              <NavLink
                isSelected={activeTab === 'courses'}
                href="#"
                name="Courses"
                Icon={IcoAcademicCap}
              />
              <NavLink
                isSelected={activeTab === 'products'}
                href="#"
                name="Products"
                Icon={IcoBook}
              />
              <NavLink
                isSelected={activeTab === 'bundles'}
                href="#"
                name="Bundles"
                Icon={IcoFolder}
              />
              <NavLink
                isSelected={activeTab === 'upsells'}
                href="#"
                name="Upsells"
                Icon={IcoChartBar}
              />
            </ul>
          </li>
        </ul>
        {!hidePromo && <UpgradeToProWidget />}
      </nav>
    </div>
  );
}

export function GuideRootPage({
  activeTab,
  data,
  children,
}: {
  activeTab: HomepageTabs;
  data: LoadedProps<typeof loadRootGuidePage>['data'];
  children: ComponentChildren;
}) {
  const showSearchModal = useRootPageSearch({
    data,
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <div class="relative isolate flex min-h-svh w-full max-lg:flex-col lg:bg-gray-100">
      {isMobileMenuOpen && (
        <SlideOver
          position="left"
          contentContainerClass="mr-16"
          close={() => setIsMobileMenuOpen(false)}
        >
          <NavBarContent activeTab={activeTab} hidePromo />
        </SlideOver>
      )}
      <div class="hidden lg:flex lg:fixed lg:inset-y-0 lg:z-50 left-0 w-64">
        <NavBarContent activeTab={activeTab} />
      </div>
      <main class="flex flex-1 flex-col p-4 lg:min-w-0 lg:pl-64 lg:pr-2 lg:pt-2">
        <div class="grow bg-white lg:shadow-sm lg:rounded-lg">
          <div class="mx-auto px-4 lg:max-w-6xl">
            <header class="flex gap-4 py-8 pb-0 mb-8">
              <Button class="lg:hidden" onClick={() => setIsMobileMenuOpen(true)}>
                <IcoList class="w-6 h-6 opacity-75" />
              </Button>
              <div class="grow">
                <Button
                  class="flex items-center justify-center w-72 focus:ring-indigo-400 focus:border-indigo-400 rounded-lg text-sm text-gray-500 border border-gray-300 p-2"
                  onClick={showSearchModal}
                >
                  <IcoSearch class="mr-2" />
                  Quick search...
                  <span class="ml-auto pl-3 text-xs text-gray-400">⌘K</span>
                </Button>
              </div>
              <UserMenu />
            </header>
            {children}
          </div>
        </div>
      </main>
    </div>
  );
}
