import { router } from '@components/router';
import { RuzcalMgmtPage } from './mgmt-page';
import { IcoClock } from '@components/icons';
import { useCurrentUser } from 'client/lib/auth';
import { ListAddAction, ListContainer, PageContent, PageHeading, PageSection } from './common';

function summarizeDuration(duration: number) {
  return `${duration}m`;
}

function EditLink(props: { label: string; href: string; duration: number; isPrivate?: boolean }) {
  return (
    <a
      href="/ruzcal-mgmt/meeting-types/edit"
      class="flex flex-col sm:gap-4 sm:flex-row rounded-md p-4 hover:bg-gray-100 transition-all text-inherit justify-between"
    >
      <span class="flex items-center gap-3">
        <span class="font-semibold">{props.label}</span>
        {props.isPrivate && (
          <span>
            <span class="bg-gray-50 border border-gray-300 rounded p-1 text-xs">Hidden</span>
          </span>
        )}
        <span class="opacity-75">{props.href}</span>
      </span>
      <span class="flex flex-row-reverse sm:flex-row justify-end items-center gap-4">
        <span class="opacity-75">during business hours</span>
        <span class="inline-flex items-center gap-1">
          <IcoClock /> {summarizeDuration(props.duration)}
        </span>
      </span>
    </a>
  );
}

function AvailabilityMangeList() {
  const username = useCurrentUser()!.name.toLowerCase().replaceAll(/[\s]+/g, '');
  return (
    <PageContent>
      <PageSection>
        <PageHeading
          title="Meeting types"
          subtitle="Manage the types of meetings that people can book."
          action={
            <ListAddAction href="/ruzcal-mgmt/meeting-types/edit" content="Add meeting type" />
          }
        />
        <ListContainer>
          <EditLink label="Quick 1:1" href={`/${username}/1:1`} duration={30} />
          <EditLink label="Deep dive" href={`/${username}/deepdive`} duration={60} />
          <EditLink label="MVP sync" href={`/${username}/mvp`} duration={90} isPrivate />
        </ListContainer>
      </PageSection>
    </PageContent>
  );
}

function Page() {
  return (
    <RuzcalMgmtPage title="Meeting types" currentPage="meetingtypes">
      <AvailabilityMangeList />
    </RuzcalMgmtPage>
  );
}

router.add({
  url: 'ruzcal-mgmt/meeting-types',
  authLevel: 'superadmin',
  render: Page,
});
