import { router } from '@components/router';
import { RuzcalMgmtPage } from './mgmt-page';
import dayjs from 'dayjs';
import { useEffect, useState } from 'preact/hooks';
import { mkdate } from '@components/date-picker';
import { IcoVideoCamera } from '@components/icons';
import { ListContainer, PageContent, PageHeading, PageSection } from './common';

type Booking = {
  title: string;
  start: Date;
  end: Date;
  duration: number;
  location: string;
  attendee: {
    email: string;
    name: string;
    notes: string;
  };
};

function EditLink(props: Booking & { isNow: boolean; isNext: boolean; isPast: boolean }) {
  return (
    <a
      href="/ruzcal-mgmt/bookings/edit"
      class={`relative flex justify-between gap-4 items-start transition-all p-3 rounded-md font-medium ${
        props.isNow
          ? 'bg-green-50 ring-2 ring-green-400 text-green-700 hover:bg-green-100 hover:text-green-800'
          : props.isNext
          ? 'bg-sky-50 text-sky-700 hover:bg-sky-100 hover:text-sky-800 mt-4 ring-1 ring-sky-200'
          : props.isPast
          ? 'opacity-75 text-inherit hover:bg-gray-100 hover:opacity-100'
          : 'text-inherit hover:bg-gray-100'
      }`}
    >
      <span class="flex flex-col sm:flex-row sm:gap-4 items-start">
        <span class="flex items-center gap-2">
          <span class="flex justify-between items-center w-32">
            <span class="font-semibold">{dayjs(props.start).format('h:mma')}</span>
            {' - '}
            <span class="opacity-75">{dayjs(props.end).format('h:mma')}</span>
          </span>
        </span>
        <span>
          <span class="opacity-75">{props.title} with</span> <span>{props.attendee.name}</span>
        </span>
      </span>
      <span class="flex flex-col sm:flex-row items-center sm:gap-4 text-xs">
        {props.isNow && (
          <span class="bg-green-500 text-white text-xs font-semibold px-1.5 rounded rounded-bl-none absolute -top-3 left-0 ring-2 ring-green-500 flex items-center gap-2">
            <span class="relative size-2 bg-green-100 rounded-full inline-flex">
              <span class="absolute inset-0 bg-green-100 rounded-full inline-flex animate-ping"></span>
            </span>
            <span class="relative">Now</span>
          </span>
        )}
        {props.isNext && (
          <span class="bg-sky-500 text-white text-xs font-semibold px-1.5 rounded rounded-bl-none absolute -top-3 left-0 ring-2 ring-sky-500 flex items-center gap-2">
            <span class="relative">Up next</span>
          </span>
        )}
        <span class="md:flex items-center gap-2 hidden">{props.duration} mins</span>
        <span class="flex items-center gap-1">
          <IcoVideoCamera />
          {props.location}
        </span>
      </span>
    </a>
  );
}

function BookingList(props: { prevDate: Date; bookings: Booking[] }) {
  const now = new Date();
  let prevDate = props.prevDate;
  return (
    <div class="flex flex-col gap-4">
      {props.bookings.map((b) => {
        const isNow = b.start <= now && b.end >= now;
        const isPast = !isNow && b.start < now;
        const isNext = !isNow && prevDate < now && b.start > now;
        prevDate = b.start;
        return <EditLink key={b.start} {...b} isNext={isNext} isNow={isNow} isPast={isPast} />;
      })}
    </div>
  );
}

function generateMockBookings(): Booking[] {
  const hoursFromNow = (hours: number) =>
    mkdate((dt) =>
      dt.setHours(dt.getHours() + Math.floor(hours), Math.floor(60 * hours) % 60, 0, 0),
    );
  const mkbooking = (
    opts: { hours: number } & Partial<Booking> & Partial<Booking['attendee']>,
  ): Booking => {
    const start = hoursFromNow(opts.hours);
    const duration = opts.duration || 30;
    const name = opts.name || 'Abe';
    return {
      start,
      end: mkdate((dt) => dt.setMinutes(dt.getMinutes() + duration), start),
      title: 'Quick 1:1',
      attendee: {
        name,
        email: opts.email || `${name}@example.com`,
        notes: opts.notes || '',
        ...opts.attendee,
      },
      duration,
      location: 'Zoom',
      ...opts,
    };
  };
  return [
    mkbooking({ hours: -2, title: 'Design meeting', name: 'Abe' }),
    mkbooking({ hours: 0, duration: 60, title: 'Quick 1:1', name: 'James' }),
    mkbooking({ hours: 1.5, duration: 30, title: 'Quick 1:1', name: 'Pinnochio' }),
    mkbooking({
      hours: 4,
      duration: 90,
      title: 'Technical deep dive',
      name: 'Bill Gates',
      notes: 'You better show up on time and not waste my time!',
    }),
    mkbooking({ hours: 28.5, name: 'Sally Fields' }),
    mkbooking({ hours: 32, name: 'Jane' }),
    mkbooking({ hours: 56, name: 'Mer Janice' }),
    mkbooking({ hours: 24 * 10, name: 'Sam Altman' }),
    mkbooking({ hours: 24 * 266, name: 'Halil' }),
  ];
}

function AvailabilityMangeList() {
  const [bookings, setBookings] = useState(() => {
    const arr = generateMockBookings();
    const result: Array<{ bookings: Booking[] }> = [];
    let prevDate = mkdate((dt) => dt.setHours(dt.getHours() - 48));
    for (const booking of arr) {
      if (prevDate.toDateString() !== booking.start.toDateString()) {
        result.push({ bookings: [] });
      }
      prevDate = booking.start;
      result[result.length - 1].bookings.push(booking);
    }
    return result;
  });

  useEffect(() => {
    /**
     * Redraw every 60 seconds to shift the live view, etc.
     */
    const interval = 60000;
    let timeout = setTimeout(function tick() {
      setBookings((s) => s);
      timeout = setTimeout(tick, interval);
    }, interval);
    return () => clearTimeout(timeout);
  }, []);

  const now = new Date();
  const today = mkdate((dt) => dt.setHours(0, 0, 0, 0), now);
  const tomorrow = dayjs(today).add(1, 'day').toDate();
  let prevDate = mkdate((dt) => dt.setHours(dt.getHours() - 48));
  const thisYear = today.getFullYear();

  return (
    <PageContent>
      <PageSection>
        <PageHeading
          title="Bookings"
          subtitle="Manage your upcoming bookings and view booking history."
        />
        <nav class="flex gap-4 font-medium mb-4">
          <button
            type="button"
            class="bg-gray-200 text-black p-1 px-2 hover:shadow transition-all rounded-full"
          >
            Current & upcoming
          </button>
          <button
            type="button"
            class="p-1 px-2 rounded-full hover:bg-gray-200 hover:shadow transition-all"
          >
            Booking history
          </button>
        </nav>
        <ListContainer>
          {bookings.map((b, i) => {
            const date = b.bookings[0].start;
            const startDay = date.toDateString();
            const isToday = today.toDateString() === startDay;
            const isTomorrow = tomorrow.toDateString() === startDay;
            const prev = prevDate;
            prevDate = b.bookings[b.bookings.length - 1].start;

            return (
              <div key={date}>
                <h2
                  class={`font-semibold text-sky-700 p-2 px-3 uppercase text-xs tracking-wide ${
                    i === 0 ? '' : 'pt-8 border-t-2'
                  }`}
                >
                  {isToday && 'Today, '}
                  {isTomorrow && 'Tomorrow, '}
                  {dayjs(date).format(
                    `dddd, MMMM D${date.getFullYear() !== thisYear ? ', YYYY' : ''}`,
                  )}
                </h2>
                <BookingList bookings={b.bookings} prevDate={prev} />
              </div>
            );
          })}
        </ListContainer>
      </PageSection>
    </PageContent>
  );
}

function Page() {
  return (
    <RuzcalMgmtPage title="Bookings" currentPage="bookings">
      <AvailabilityMangeList />
    </RuzcalMgmtPage>
  );
}

router.add({
  url: 'ruzcal-mgmt',
  authLevel: 'superadmin',
  render: Page,
});

router.add({
  url: 'ruzcal-mgmt/bookings',
  authLevel: 'superadmin',
  render: Page,
});
