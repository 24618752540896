import { IcoArrowLeft, IcoArrowRight } from '@components/icons';
import { MeetingDetail } from './types';
import { autoFocusSelf } from 'client/utils/autofocus';
import { Button } from '@components/buttons';
import { router } from '@components/router';
import { MeetingSummary } from './meeting-summary';

type Props = MeetingDetail & {
  onBack(): void;
};

export function ConfirmBooking(props: Props) {
  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen">
      <section class="p-8 bg-white rounded-2xl max-w-full w-3xl an-scale-in flex-col gap-8 grid sm:grid-cols-2">
        <div>
          <button
            type="button"
            class="inline-flex gap-2 items-center mb-4 font-medium"
            onClick={props.onBack}
          >
            <IcoArrowLeft />
            Back
          </button>
          <MeetingSummary {...props} />
        </div>
        <form
          class="flex flex-col gap-4 border-t pt-8 sm:pt-0 sm:border-0 sm:border-l sm:pl-8"
          onSubmit={(e) => {
            e.preventDefault();
            router.goto(`/ruzcal/bookings/example${location.search}`);
          }}
        >
          <label class="flex flex-col gap-1">
            <span class="font-medium">Your name</span>
            <input type="text" class="ruz-input" ref={autoFocusSelf} />
          </label>
          <label class="flex flex-col gap-1">
            <span class="font-medium">Email address</span>
            <input type="email" class="ruz-input" />
          </label>
          <label class="flex flex-col gap-1">
            <span class="font-medium">Additional notes</span>
            <textarea class="ruz-input"></textarea>
          </label>
          <footer>
            <Button class="inline-flex text-left gap-2 items-center p-2 px-4 rounded-full bg-gray-700 text-white hover:bg-black text-base">
              Schedule meeting
              <IcoArrowRight class="size-4 shrink-0" />
            </Button>
          </footer>
        </form>
      </section>
    </div>
  );
}
