import { router } from '@components/router';
import { GuideRootPage, loadRootGuidePage as load } from '@components/guide-root-page';
import { GuideStats } from './guide-stats';
import { RecentActivities } from './activities-feed';
import { LoadedProps } from 'client/lib/loaders';

type Props = LoadedProps<typeof load>;

function Page(props: Props) {
  return (
    <GuideRootPage data={props.data} activeTab="overview">
      <GuideStats />
      <RecentActivities />
    </GuideRootPage>
  );
}

router.add({
  url: '/overview',
  render: Page,
  load,
});
