import { Button } from '@components/buttons';
import { IcoPlus } from '@components/icons';
import { ComponentChildren } from 'preact';

export function PageHeading(props: {
  title: ComponentChildren;
  subtitle?: ComponentChildren;
  action?: ComponentChildren;
}) {
  return (
    <header class="flex justify-between items-end gap-4">
      <div class="text-gray-600">
        <h1 class="font-semibold text-lg text-gray-800">{props.title}</h1>
        {props.subtitle && <p>{props.subtitle}</p>}
      </div>
      {props.action}
    </header>
  );
}

export function PageContent(props: { children: ComponentChildren }) {
  return (
    <section class="flex flex-col gap-10 py-8 p-6 md:px-0 bg-gray-50 mx-auto w-full max-w-2xl">
      {props.children}
    </section>
  );
}

export function PageSection(props: { children: ComponentChildren }) {
  return <div class="flex flex-col gap-4">{props.children}</div>;
}

export function ListContainer(props: { children: ComponentChildren }) {
  return (
    <div class="flex flex-col gap-4 bg-white rounded-2xl shadow p-4 py-5">{props.children}</div>
  );
}

export function ListAddAction({
  content,
  ...props
}: {
  href?: string;
  onClick?(): void;
  content: ComponentChildren;
}) {
  return (
    <Button
      class="bg-indigo-600 text-white hover:bg-indigo-700 transition-all p-2 px-3 rounded-md font-medium whitespace-nowrap inline-flex items-center gap-1"
      {...props}
    >
      <IcoPlus /> {content}
    </Button>
  );
}
