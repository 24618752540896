import { router } from '@components/router';
import { GuideRootPage, loadRootGuidePage as load } from '@components/guide-root-page';
import { GuideChecklist } from './guide-checklist';
import { Resources } from './resources';
import { CreateWidget } from './create-widget';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';
import { useCurrentUser } from 'client/lib/auth';
import { LoadedProps } from 'client/lib/loaders';

type Props = LoadedProps<typeof load>;

function Page(props: Props) {
  const user = useCurrentUser();

  return (
    <GuideRootPage data={props.data} activeTab="welcome">
      <div class="flex items-center justify-between bg-gradient-to-b from-gray-100 to-indigo-50 rounded-2xl px-8 py-8 text-gray-900 mb-8 relative">
        <div>
          <h2 class="text-3xl font-semibold tracking-tight mb-2">👋 Welcome, {user?.name}!</h2>
          <h3 class="text-base">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          </h3>
        </div>
        <img
          class="absolute right-8 w-48 h-48"
          data-attribution="https://undraw.co"
          src={`${RUZUKU_ASSETS_BASE_URL}/undraw_welcoming_re_x0qo.svg`}
        />
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6 mb-4 lg:mb-8">
        <GuideChecklist />
        <Resources />
      </div>
      <CreateWidget />
    </GuideRootPage>
  );
}

router.add({
  url: '/welcome',
  render: Page,
  load,
});
