import { router } from '@components/router';
import { useCurrentUser } from 'client/lib/auth';
import { AvailableBlock, Props as AvailableProps } from './availability-summary';
import { RuzcalMgmtPage } from './mgmt-page';
import { ListAddAction, ListContainer, PageContent, PageHeading, PageSection } from './common';

function EditLink(props: AvailableProps) {
  return (
    <a
      href="/ruzcal-mgmt/availability/edit"
      class="block p-3 hover:bg-gray-100 transition-all text-inherit rounded-md"
    >
      <AvailableBlock {...props} />
    </a>
  );
}

function AvailabilityMangeList() {
  const user = useCurrentUser()!;
  return (
    <PageContent>
      <PageSection>
        <PageHeading
          title="Manage your availability"
          subtitle="Define blocks of time when you're available for booking."
          action={
            <ListAddAction href="/ruzcal-mgmt/availability/edit" content="Add availability" />
          }
        />
        <ListContainer>
          <EditLink
            label="Business hours"
            isDefault
            renderTimeZone={user.timezone}
            scheduleTimeZone={user.timezone}
            availability={{
              1: [{ start: '9:00 AM', end: '5:00 PM' }],
              2: [{ start: '9:00 AM', end: '5:00 PM' }],
              3: [{ start: '9:00 AM', end: '5:00 PM' }],
              4: [{ start: '9:00 AM', end: '5:00 PM' }],
              5: [{ start: '9:00 AM', end: '5:00 PM' }],
            }}
          />
          <EditLink
            label="Weekends"
            renderTimeZone={user.timezone}
            scheduleTimeZone={user.timezone}
            availability={{
              0: [{ start: '1:00 PM', end: '3:00 PM' }],
              6: [{ start: '1:00 PM', end: '3:00 PM' }],
            }}
          />
        </ListContainer>
      </PageSection>
      <PageSection>
        <PageHeading
          title="Date overrides"
          subtitle="Remove specific dates and times from your public availability."
          action={<ListAddAction href="/ruzcal-mgmt/availability/omit" content="Add override" />}
        />
      </PageSection>
    </PageContent>
  );
}

function Page() {
  return (
    <RuzcalMgmtPage title="Availability" currentPage="availability">
      <AvailabilityMangeList />
    </RuzcalMgmtPage>
  );
}

router.add({
  url: 'ruzcal-mgmt/availability',
  authLevel: 'superadmin',
  render: Page,
});
