import { h } from 'preact';
import { router } from '@components/router';
import { useCurrentUser } from 'client/lib/auth';
import { IcoArrowLeft, IcoPlus, IcoTrash } from '@components/icons';
import { RuzcalMgmtPage } from './mgmt-page';
import { useMemo, useState } from 'preact/hooks';
import { Toggle } from '@components/toggle';
import dayjs from 'dayjs';
import { BtnPrimary, Button } from '@components/buttons';
import { Combobox } from './combobox';
import { focusRef } from 'client/utils/autofocus';
import { PageContent, PageHeading, PageSection } from './common';

type AvailableTime = {
  id: string;
  /**
   * E.g. 12:00am
   */
  start: string;
  /**
   * E.g. 3:00pm
   */
  end: string;
  /**
   * An array of days (0-6) to which this time-range applies.
   */
  days: number[];
};

type Availability = {
  title: string;
  isDefault: boolean;
  renderTimeZone: string;
  scheduleTimeZone: string;
  availability: AvailableTime[];
};

function addMins(mins: number, time?: string) {
  if (!time) {
    return;
  }

  const date = dayjs('2000-01-01 ' + time).add(mins, 'minute');
  if (date.isValid()) {
    return date.format('h:mma');
  }
}

function parseTime(s: string, hour12: boolean) {
  const value = s.toLowerCase();
  const ampm = value.includes('a') ? 'am' : 'pm';
  const [h, m] = value.split(/[^0-9]/);
  const hh = parseInt(h, 10) || new Date().getHours();
  const mm = `${parseInt(m, 10) || 0}`.padEnd(2, '0');
  return `${hh}:${mm}${hour12 ? ampm : ''}`;
}

function TimeInput({
  hour12,
  ...props
}: Omit<h.JSX.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  value: string;
  onChange(value: string): void;
  hour12: boolean;
}) {
  const times = useMemo(() => {
    return new Array(24).fill(0).flatMap((_, i) => {
      const hour = hour12 ? i % 12 || 12 : i;
      const ampm = hour12 ? (i >= 12 ? 'pm' : 'am') : '';
      return [`${hour}:00${ampm}`, `${hour}:30${ampm}`];
    });
  }, []);
  return (
    <Combobox
      class="inline-ruz-input w-24 p-3 px-4 text-sm"
      values={times}
      {...props}
      onBlur={(e: any) => {
        props.onChange(parseTime(e.target.value, hour12));
      }}
    />
  );
}

function EditTimeSlot({
  value,
  onChange,
  onDelete,
}: {
  value: AvailableTime;
  onChange(value: AvailableTime): void;
  onDelete(): void;
}) {
  const days = useMemo(
    () =>
      new Array(7).fill(0).map((_, i) => ({
        day: i,
        desc: dayjs().set('day', i).format('ddd'),
      })),
    [],
  );
  return (
    <div class="flex flex-col gap-4 p-6 rounded-lg bg-white shadow">
      <section class="flex justify-between gap-4">
        <div class="inline-flex items-center gap-2">
          <TimeInput
            name="start"
            placeholder="9:00am"
            value={value.start}
            onChange={(start) => onChange({ ...value, start })}
            hour12
          />
          -
          <TimeInput
            name="end"
            placeholder="5:00pm"
            value={value.end}
            onChange={(end) => onChange({ ...value, end })}
            hour12
          />
        </div>
        <span>
          <Button
            onClick={onDelete}
            class="hover:bg-red-50 size-8 md:size-10 hover:text-red-600 rounded-md aspect-square inline-flex items-center justify-center ml-auto hover:ring-1 hover:ring-red-200"
          >
            <IcoTrash />
          </Button>
        </span>
      </section>
      <section class="grid grid-cols-7 gap-2 select-none">
        {days.map((d) => {
          const checked = value.days.includes(d.day);
          return (
            <label key={d} class="cursor-pointer relative group flex flex-col bg-white">
              <input
                type="checkbox"
                class="absolute opacity-0 z-0"
                checked={checked}
                value={d.day}
                name="days[]"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const toggled = value.days.filter((x) => x !== d.day);
                  if (!checked) {
                    toggled.push(d.day);
                  }
                  onChange({
                    ...value,
                    days: toggled,
                  });
                }}
              />
              <span
                class={`relative z-10 flex items-center justify-center transition-all px-2 py-1 font-medium rounded group border border-gray-300 ${
                  checked ? 'bg-indigo-50 border-indigo-200' : ''
                } group-focus-within:ring-2 gap-2 hover:ring`}
              >
                <span
                  class={`size-2 hidden sm:inline-block rounded-sm ${
                    checked ? 'bg-indigo-500' : 'bg-gray-200'
                  }`}
                ></span>
                <span>{d.desc}</span>
              </span>
            </label>
          );
        })}
      </section>
    </div>
  );
}

function Page() {
  const user = useCurrentUser()!;
  const [state, setState] = useState<Availability>(() => ({
    title: 'Business hours',
    isDefault: true,
    renderTimeZone: user.timezone,
    scheduleTimeZone: user.timezone,
    availability: [
      {
        id: 'avl-demo123',
        start: '9:00am',
        end: '5:00pm',
        days: [1, 2, 3, 4, 5],
      },
    ],
  }));
  return (
    <RuzcalMgmtPage title="Edit availability" currentPage="availability">
      <PageContent>
        <PageSection>
          <header>
            <a href="/ruzcal-mgmt/availability" class="inline-flex items-center gap-2">
              <IcoArrowLeft />
              Back to availability
            </a>
            <PageHeading title={`Edit ${state.title}`} />
          </header>
          <div class="flex flex-col gap-8 max-w-2xl">
            <label class="flex flex-col gap-1 max-w-80">
              <span class="font-medium">Title</span>
              <input
                type="text"
                name="title"
                class="inline-ruz-input px-4 p-3 text-sm"
                value={state.title}
                ref={focusRef}
                onInput={(e: any) => setState((s) => ({ ...s, title: e.target.value }))}
              />
            </label>
            <section class="flex flex-col gap-1">
              <span class="font-medium">
                Available times{' '}
                <span class="text-gray-500">({user.timezone.replaceAll('_', ' ')} time)</span>
              </span>
              <div class="flex flex-col gap-6">
                {state.availability.map((avl, i) => (
                  <EditTimeSlot
                    key={avl.id}
                    value={avl}
                    onDelete={() =>
                      setState((s) => ({
                        ...s,
                        availability: s.availability.filter((x) => x !== avl),
                      }))
                    }
                    onChange={(value) =>
                      setState((s) => ({
                        ...s,
                        availability: s.availability.map((x, y) => (y === i ? value : x)),
                      }))
                    }
                  />
                ))}

                <footer>
                  <Button
                    class="flex items-center gap-2 p-3 px-4 bg-gray-200 text-gray-900 hover:shadow-md font-medium rounded-lg"
                    onClick={() => {
                      const prev = state.availability[state.availability.length - 1];
                      setState((s) => ({
                        ...s,
                        availability: [
                          ...s.availability,
                          {
                            id: `tmp-${Date.now().toString()}`,
                            start: addMins(30, prev?.end) || '9:00am',
                            end: addMins(90, prev?.end) || '5:00pm',
                            days: s.availability[0]?.days || [1, 2, 3, 4, 5],
                          },
                        ],
                      }));
                    }}
                  >
                    <IcoPlus /> Add a time window
                  </Button>
                </footer>
              </div>
            </section>
            <label class="inline-flex items-center gap-3 cursor-pointer select-none">
              <Toggle
                name="isDefault"
                checked={state.isDefault}
                onClick={() => setState((s) => ({ ...s, isDefault: !s.isDefault }))}
              />
              Is default
            </label>
            <footer class="flex gap-4">
              <BtnPrimary href="/ruzcal-mgmt/availability" class="p-3 px-4">
                Save availability
              </BtnPrimary>
              <Button
                href="/ruzcal-mgmt/availability"
                class="text-inherit inline-flex items-center justify-center rounded-md hover:bg-gray-100 px-4 transition-all"
              >
                Cancel
              </Button>
            </footer>
          </div>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}

router.add({
  isPublic: true,
  url: 'ruzcal-mgmt/availability/edit',
  render: Page,
});
