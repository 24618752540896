import { router } from '@components/router';
import {
  IcoArrowLeft,
  IcoBan,
  IcoBell,
  IcoChevronDown,
  IcoClock,
  IcoCube,
  IcoPencil,
  IcoVideoCamera,
} from '@components/icons';
import { RuzcalMgmtPage } from './mgmt-page';
import { useState } from 'preact/hooks';
import { BtnPrimary, Button } from '@components/buttons';
import { AutosizeText } from '@components/autosize-text';
import { useCurrentUser } from 'client/lib/auth';
import { Toggle } from '@components/toggle';
import { focusRef } from 'client/utils/autofocus';
import { pluralize } from 'shared/formatting';
import { ComponentChildren } from 'preact';
import { PageContent, PageHeading, PageSection } from './common';

type Availability = {
  title: string;
  description: string;
  href: string;
  duration: number;
  bufferPrefix: number;
  bufferSuffix: number;
  minNotice: number;
  hasDailyLimit: boolean;
  maxPerDay: number;
};

function FormPanel(props: { title: ComponentChildren; children: ComponentChildren }) {
  return (
    <div class="relative flex flex-col gap-4">
      <h2 class="text-lg border-b-2 pb-4">{props.title}</h2>
      {props.children}
    </div>
  );
}

function Page() {
  const username = useCurrentUser()!.name.toLowerCase().replaceAll(/[\s]+/g, '');
  const [expand, setExpand] = useState(true);
  const [state, setState] = useState<Availability>(() => ({
    title: 'Quick 1:1',
    description: '',
    href: '1:1',
    duration: 30,
    bufferPrefix: 10,
    bufferSuffix: 15,
    minNotice: 120,
    hasDailyLimit: true,
    maxPerDay: 2,
  }));
  return (
    <RuzcalMgmtPage title="Edit meeting type" currentPage="meetingtypes">
      <PageContent>
        <PageSection>
          <header>
            <a href="/ruzcal-mgmt/meeting-types" class="inline-flex items-center gap-2">
              <IcoArrowLeft />
              Back to meeting types
            </a>
            <PageHeading title={`Edit ${state.title}`} />
          </header>
          <div class="flex flex-col gap-14">
            <FormPanel title="Basics">
              <div class="flex flex-col gap-8 max-w-2xl">
                <label class="flex flex-col gap-1">
                  <span>Title</span>
                  <input
                    type="text"
                    name="title"
                    class="ruz-input p-3 px-4"
                    ref={focusRef}
                    value={state.title}
                    placeholder="e.g. 1:1"
                    onInput={(e: any) => setState((s) => ({ ...s, title: e.target.value }))}
                  />
                </label>
                <label class="flex flex-col gap-1">
                  <span>Duration</span>
                  <span class="border border-gray-300 rounded inline-flex w-content">
                    <input
                      type="text"
                      name="href"
                      inputMode="numeric"
                      class="inline-ruz-input p-3 px-4 rounded-r-none border-0 relative z-10 w-14 focus:ring-2 transition-all"
                      value={state.duration}
                      placeholder="e.g. 1:1"
                      autoFocus
                      onInput={(e: any) => setState((s) => ({ ...s, duration: e.target.value }))}
                    />
                    <span class="flex items-center whitespace-nowrap px-4 rounded-r text-gray-600 bg-gray-50 border-l border-gray-300">
                      minutes
                    </span>
                  </span>
                </label>
                <label class="flex flex-col gap-1">
                  <span>Description</span>
                  <AutosizeText
                    containerClass="bg-white"
                    class="ruz-input min-h-20 p-3 px-4"
                    placeholder="Briefly describe the meeting"
                    value={state.description}
                  />
                </label>
                <label class="flex flex-col gap-1">
                  <span>URL</span>
                  <span class="border border-gray-300 rounded inline-flex max-w-full">
                    <span class="flex items-center whitespace-nowrap p-3 bg-gray-50 rounded-l text-gray-600 border-r border-gray-300">
                      /{username}/
                    </span>
                    <input
                      type="text"
                      name="href"
                      class="inline-ruz-input p-3 px-4 rounded-l-none w-full border-0 grow focus:ring-2 transition-all"
                      value={state.href}
                      placeholder="e.g. 1:1"
                      autoFocus
                      onInput={(e: any) => setState((s) => ({ ...s, label: e.target.value }))}
                    />
                  </span>
                </label>
              </div>
            </FormPanel>

            <FormPanel title="Availability & location">
              {!expand && (
                <>
                  <div class="flex flex-col gap-2">
                    <span class="font-semibold">Business hours</span>
                    <div class="flex items-center gap-2 text-gray-600">
                      <IcoClock />
                      <span>Mon-Fri 9am-5pm</span>
                    </div>
                    <div class="flex items-center gap-2 text-gray-600">
                      <IcoVideoCamera />
                      <span>Zoom</span>
                    </div>
                    <div class="flex items-center gap-2 text-gray-600">
                      <IcoCube />
                      <span class="text-gray-600">
                        Block out {state.bufferPrefix} {pluralize('minute', state.bufferPrefix)}{' '}
                        before and {state.bufferSuffix} {pluralize('minute', state.bufferSuffix)}{' '}
                        after
                      </span>
                    </div>
                    <div class="flex items-center gap-2 text-gray-600">
                      <IcoBell />
                      <span class="text-gray-600">
                        Minimum notice {state.minNotice} {pluralize('minute', state.minNotice)}
                      </span>
                    </div>
                    {state.hasDailyLimit && (
                      <div class="flex items-center gap-2 text-gray-600">
                        <IcoBan />
                        <span class="text-gray-600">{`Limit ${state.maxPerDay} per day`}</span>
                      </div>
                    )}
                    <button
                      type="button"
                      class="flex items-center gap-2 text-indigo-600 hover:underline text-left"
                      onClick={() => setExpand(true)}
                    >
                      <IcoPencil />
                      Edit availability and location
                    </button>
                  </div>
                </>
              )}
              {expand && (
                <div class="flex flex-col gap-8 max-w-2xl">
                  <label class="flex flex-col gap-1 max-w-80">
                    <span>Availability</span>
                    <button class="border border-gray-300 rounded inline-flex items-center p-3 px-4 gap-2 ruz-input cursor-pointer focus:ring-1 transition-all outline-none bg-white">
                      <span class="font-semibold">Business hours</span>
                      <span class="text-gray-600">Mon-Fri 9am-5pm</span>
                      <IcoChevronDown class="size-4 opacity-75 ml-auto" />
                    </button>
                  </label>
                  <label class="flex flex-col gap-1 max-w-80">
                    <span>Location</span>
                    <button class="border border-gray-300 rounded inline-flex items-center p-3 px-4 gap-2 ruz-input cursor-pointer focus:ring-1 transition-all outline-none bg-white">
                      <IcoVideoCamera />
                      <span class="font-semibold">Zoom</span>
                      <IcoChevronDown class="size-4 opacity-75 ml-auto" />
                    </button>
                  </label>
                  <div class="flex flex-col gap-1">
                    <span>Reserve time around the meeting</span>
                    <section class="flex gap-6">
                      <label class="flex flex-col gap-1">
                        <span class="border border-gray-300 rounded inline-flex w-content">
                          <input
                            type="text"
                            name="bufferPrefix"
                            inputMode="numeric"
                            class="inline-ruz-input rounded-r-none border-0 relative z-10 w-14 focus:ring-2 transition-all p-3 px-4"
                            value={state.bufferPrefix}
                            placeholder="e.g. 1:1"
                            autoFocus
                            onInput={(e: any) => setState((s) => ({ ...s, label: e.target.value }))}
                          />
                          <span class="flex items-center whitespace-nowrap px-4 rounded-r text-gray-600 bg-gray-50 border-l border-l-gray-300">
                            minutes before
                          </span>
                        </span>
                      </label>
                      <label class="flex flex-col gap-1">
                        <span class="border border-gray-300 rounded inline-flex w-content">
                          <input
                            type="text"
                            name="bufferSuffix"
                            inputMode="numeric"
                            class="inline-ruz-input p-3 px-4 rounded-r-none border-0 relative z-10 w-14 focus:ring-2 transition-all"
                            value={state.bufferSuffix}
                            placeholder="e.g. 1:1"
                            autoFocus
                            onInput={(e: any) => setState((s) => ({ ...s, label: e.target.value }))}
                          />
                          <span class="flex items-center whitespace-nowrap px-4 rounded-r text-gray-600 bg-gray-50 border-l border-l-gray-300">
                            minutes after
                          </span>
                        </span>
                      </label>
                    </section>
                  </div>
                  <label class="flex flex-col gap-1">
                    <span>Minimum notice</span>
                    <span class="border border-gray-300 rounded inline-flex w-content">
                      <input
                        type="text"
                        name="href"
                        inputMode="numeric"
                        class="inline-ruz-input p-3 px-4 rounded-r-none border-0 relative z-10 w-14 focus:ring-2 transition-all"
                        value={state.minNotice}
                        placeholder="e.g. 1:1"
                        autoFocus
                        onInput={(e: any) => setState((s) => ({ ...s, label: e.target.value }))}
                      />
                      <span class="flex items-center whitespace-nowrap px-4 rounded-r text-gray-600 bg-gray-50 border-l border-l-gray-300">
                        minutes
                      </span>
                    </span>
                  </label>
                  <section class="flex flex-col gap-4">
                    <label class="flex gap-3 items-center cursor-pointer">
                      <Toggle
                        checked={state.hasDailyLimit}
                        onClick={() => setState((s) => ({ ...s, hasDailyLimit: !s.hasDailyLimit }))}
                      />
                      <span>Limit how many meetings can be booked in a day</span>
                    </label>
                    {state.hasDailyLimit && (
                      <label class="flex flex-col gap-1">
                        <span class="border border-gray-300 rounded inline-flex w-content">
                          <input
                            type="text"
                            name="href"
                            inputMode="numeric"
                            class="inline-ruz-input p-3 px-4 rounded-r-none border-0 relative z-10 w-14 focus:ring-2 transition-all"
                            value={state.maxPerDay}
                            placeholder="e.g. 1:1"
                            autoFocus
                            onInput={(e: any) => setState((s) => ({ ...s, label: e.target.value }))}
                          />
                          <span class="flex items-center whitespace-nowrap px-4 rounded-r text-gray-600 bg-gray-50 border-l border-l-gray-300">
                            per day
                          </span>
                        </span>
                      </label>
                    )}
                  </section>
                </div>
              )}
            </FormPanel>
          </div>
          <footer class="flex gap-4 sticky bottom-0 bg-gray-50 py-4 z-10 px-2 -mx-2 max-w-2xl">
            <BtnPrimary href="/ruzcal-mgmt/meeting-types" class="px-4">
              Save meeting type
            </BtnPrimary>
            <Button
              href="/ruzcal-mgmt/meeting-types"
              class="text-inherit inline-flex items-center justify-center rounded-md hover:bg-gray-100 px-4 transition-all"
            >
              Cancel
            </Button>
          </footer>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}

router.add({
  isPublic: true,
  url: 'ruzcal-mgmt/meeting-types/edit',
  render: Page,
});
