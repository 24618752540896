import { h } from 'preact';
import { getDate, monthFormatter, weekdayColumnFormatter } from './dateutil';
import { IcoChevronLeft, IcoChevronRight } from '@components/icons';
import { shiftMonth } from '@components/date-picker';
import { useRef } from 'preact/hooks';

type RendererOpts = { date: Date; today: Date };
type DateRenderer = (opts: RendererOpts) => h.JSX.Element | null;

function getDates(month: Date): Date[] {
  // We'll always have 5 rows just to keep the calendar stable
  const result: Date[] = [];
  // Move to the start of the month
  const start = new Date(month.getFullYear(), month.getMonth(), 1);
  // Move to the start of the week
  start.setDate(start.getDate() - start.getDay());
  let count = 0;

  for (let y = 0; y < 5; ++y) {
    for (let x = 0; x < 7; ++x) {
      const dt = new Date(start);
      dt.setDate(dt.getDate() + count);
      result.push(dt);
      ++count;
    }
  }

  return result;
}

function useTransitionTracker(yyyymm: string) {
  const { current } = useRef({
    yyyymm,
    animation: 'an-fade-in-left',
  });
  if (current.yyyymm > yyyymm) {
    current.animation = 'an-fade-in-right';
  } else if (current.yyyymm < yyyymm) {
    current.animation = 'an-fade-in-left';
  }
  current.yyyymm = yyyymm;
  return current.animation;
}

const defaultDateRenderer: DateRenderer = (opts) => {
  const isAvailable = opts.date >= opts.today;
  return (
    <span
      class={`rounded flex items-center justify-center aspect-square transition-all ${
        isAvailable ? 'font-semibold text-gray-900' : 'text-gray-500'
      }`}
    >
      {opts.date.getDate()}
    </span>
  );
};

const defaultIsAvailable = (opts: RendererOpts) => opts.today <= opts.date;

export function Calendar(props: {
  focusedDate: Date;
  setFocusedDate(date: Date): void;
  onSelect(date: Date): void;
  renderDate: DateRenderer;
  isAvailable?(opts: RendererOpts): boolean;
  navClass?: string;
}) {
  const {
    focusedDate,
    setFocusedDate,
    onSelect,
    renderDate,
    isAvailable = defaultIsAvailable,
  } = props;
  const yyyymm = `${focusedDate.getFullYear()}-${focusedDate
    .getMonth()
    .toString()
    .padStart(2, '0')}`;
  const today = getDate();
  const dates = getDates(focusedDate);
  const transition = useTransitionTracker(yyyymm);

  return (
    <section class="flex flex-col gap-4">
      <header class="flex items-center gap-4 justify-between lg:px-2">
        <h2 key={yyyymm} class={`font-semibold ${transition}`}>
          {monthFormatter.format(focusedDate)}
        </h2>
        <nav class={`flex items-center gap-2 ${props.navClass || ''}`}>
          <button
            type="button"
            class="inline-flex items-center justify-center p-2 aspect-square rounded focus:ring-2 hover:bg-gray-100 transition-all"
            onClick={() => setFocusedDate(shiftMonth(focusedDate, -1))}
          >
            <IcoChevronLeft />
          </button>
          <button
            type="button"
            class="inline-flex items-center justify-center p-2 aspect-square rounded focus:ring-2 hover:bg-gray-100 transition-all"
            onClick={() => setFocusedDate(shiftMonth(focusedDate, 1))}
          >
            <IcoChevronRight />
          </button>
        </nav>
      </header>
      <div class={`grid grid-cols-7 gap-1 ${transition}`} key={yyyymm}>
        {new Array(7).fill(0).map((_, d) => (
          <span key={d} class="text-gray-500 text-center p-2 text-xs">
            {weekdayColumnFormatter.format(dates[d])}
          </span>
        ))}
        {dates.map((date) => {
          if (date.getMonth() !== focusedDate.getMonth()) {
            return <span key={date}></span>;
          }
          const opts = { date, today };
          return (
            <button
              type="button"
              key={date}
              disabled={!isAvailable(opts)}
              onClick={() => onSelect(date)}
            >
              {renderDate(opts) || defaultDateRenderer(opts)}
            </button>
          );
        })}
      </div>
    </section>
  );
}
