import dayjs from 'dayjs';

export type DateRange = {
  /**
   * When the block starts, in UTC.
   */
  start: Date;
  /**
   * When the block ends, in UTC.
   */
  end: Date;
};

export type TimeRange = {
  /**
   * 11:30AM
   */
  start: string;
  /**
   * 2:45PM
   */
  end: string;
};

/**
 * An allocated block of time, as well as the type of meeting associated
 * with it. This data allows us to determine if a meeting-type's maximum
 * daily limit has been met.
 */
type BookedRange = DateRange & { meetingType: string };

export type AvailabilityOpts = {
  /**
   * The type of meeting, used to determine if the max per day
   * has been met.
   */
  meetingType: string;
  /**
   * The maximum number of these per day (<= 0 is unlimited).
   */
  maxPerDay: number;
  /**
   * The booked meetings / unavailable time ranges. The start / end
   * dates for these bookings *includes* the prefix / suffix buffer
   * times.
   */
  unavailable: BookedRange[];
  /**
   * The meeting duration in minutes.
   */
  duration: number;
  /**
   * The time zone of the meeting host.
   */
  hostTimeZone: string;
  /**
   * The time zone of the attendee.
   */
  attendeeTimeZone: string;
  /**
   * The date whose *day* will be used to generate availability.
   */
  date: Date;
  /**
   * Availability windows, indexed by day of week (e.g. 0 = sunday, 6 = saturday)
   */
  availability: Record<number, TimeRange[]>;
  /**
   * The number of minutes to reserve before the meeting starts.
   */
  prefixBufferTime: number;
  /**
   * The number of minutes to reserve after the meeting ends.
   */
  suffixBufferTime: number;
  /**
   * The minimum number of minutes into the future that a meeting should
   * be scheduled. This prevents someone from scheduling a meeting that
   * starts one minute from now, preventing the host from noticing it..
   */
  minNotice: number;
};

/**
 * Convert time into a standard format (e.g. 2pm -> 2:00 PM)
 */
function sanitizeTime(time: string) {
  time = time.toLowerCase();
  const [h, m] = time.split(/[^0-9]+/);
  const suffix = time.includes('a') ? ' AM' : time.includes('p') ? ' PM' : '';
  return `${h}:${m ? m : '00'}${suffix}`;
}

/**
 * Convert the basic availability time ranges into date time ranges.
 */
function* generateDateRanges(opts: AvailabilityOpts) {
  const targetDateStr = dayjs(opts.date).format('YYYY-MM-DD');
  for (const dayShift of [-1, 0, 1]) {
    const hostDay = dayjs.tz(targetDateStr, opts.hostTimeZone).add(dayShift, 'days');
    const weekday = hostDay.day();
    const availableTimes = opts.availability[weekday];
    if (!availableTimes) {
      continue;
    }
    for (const range of availableTimes) {
      let curr = dayjs.tz(
        hostDay.format('YYYY-MM-DD') + ' ' + sanitizeTime(range.start),
        opts.hostTimeZone,
      );
      const end = dayjs.tz(
        hostDay.format('YYYY-MM-DD') + ' ' + sanitizeTime(range.end),
        opts.hostTimeZone,
      );
      while (true) {
        const rangeEnd = curr.add(opts.duration, 'minute');
        const range: DateRange = {
          start: curr.toDate(),
          end: rangeEnd.toDate(),
        };
        if (rangeEnd.isAfter(end)) {
          break;
        }
        yield range;
        curr = rangeEnd;
      }
    }
  }
}

/**
 * Create a function which filters out any date ranges which
 * overlap with the specified array. The array and the ranges
 * passed to the `isUnavailable` function are expected to be
 * sorted.
 */
function makeDateRangeFilter(unavailable: DateRange[]) {
  let i = 0;

  return function isUnavailable(range: DateRange) {
    while (i < unavailable.length) {
      const curr = unavailable[i];

      // The range is after our current unavailable block,
      // so we'll move forward.
      if (range.start >= curr.end) {
        ++i;
        continue;
      }

      // The range has no overlap
      if (range.end <= curr.start) {
        return false;
      }

      return true;
    }

    return false;
  };
}

/**
 * Adjust the unavailable blocks by the prefix / suffix buffers.
 */
function sanitizeUnavailableBlocks(opts: AvailabilityOpts) {
  const unavailable = opts.unavailable;
  if (opts.prefixBufferTime || opts.suffixBufferTime) {
    const prefix = -(opts.prefixBufferTime || 0);
    const suffix = opts.suffixBufferTime || 0;
    return unavailable.map((x) => ({
      ...x,
      start: dayjs(x.start).add(prefix, 'minute').toDate(),
      end: dayjs(x.end).add(suffix, 'minute').toDate(),
    }));
  }
  return unavailable;
}

/**
 * Determine if the specified meeting type has met its daily max.
 */
function isAtDailyMax(opts: AvailabilityOpts) {
  if (opts.maxPerDay <= 0) {
    return;
  }
  const yyyymd = dayjs.tz(opts.date, opts.hostTimeZone).format('YYYYMD');
  const numBooked = opts.unavailable.reduce((acc, x) => {
    if (x.meetingType !== opts.meetingType) {
      return acc;
    }
    if (dayjs(x.start).tz(opts.hostTimeZone).format('YYYYMD') !== yyyymd) {
      return acc;
    }
    return acc + 1;
  }, 0);

  return numBooked >= opts.maxPerDay;
}

/**
 * Given the specified options, generate a list of available date ranges.
 */
export function* getAvailability(opts: AvailabilityOpts) {
  const minStart = dayjs().add(opts.minNotice, 'minute');
  const isUnavailable = makeDateRangeFilter(sanitizeUnavailableBlocks(opts));
  const targetDateStr = dayjs.tz(opts.date, opts.attendeeTimeZone).format('YYYY-MM-DD');

  if (isAtDailyMax(opts)) {
    return;
  }

  for (const range of generateDateRanges(opts)) {
    if (isUnavailable(range)) {
      continue;
    }
    if (minStart.isAfter(range.start)) {
      continue;
    }
    const startTz = dayjs.tz(range.start, opts.attendeeTimeZone);
    if (startTz.format('YYYY-MM-DD') === targetDateStr) {
      yield range;
    }
  }
}
