import dayjs from 'dayjs';
import { TimeRange } from './scheduling';

function to24Hour(hour: string, ampm: string) {
  const h = parseInt(hour, 10);
  if (ampm === '24') {
    return h;
  }
  if (h === 12) {
    return ampm === 'am' ? 0 : 12;
  }
  return ampm === 'am' ? h : h + 12;
}

export function summarizeDays(days: number[]) {
  const now = dayjs();
  const dayName = (d: number) => now.set('day', d).format('ddd');
  const makeTracker = (day: number) => ({ start: day, end: day });
  const summarize = (tracker: { start: number; end: number }) =>
    tracker.start === tracker.end
      ? dayName(tracker.start)
      : `${dayName(tracker.start)}-${dayName(tracker.end)}`;
  const result = [];
  let tracker = makeTracker(days[0]);
  for (const day of days) {
    if (day - tracker.end > 1) {
      result.push(summarize(tracker));
      tracker = makeTracker(day);
    }
    tracker.end = day;
  }
  if (tracker) {
    result.push(summarize(tracker));
  }
  return result.join(', ');
}

/**
 * Given an available block of day / times, the schedule time-zone, and the
 * current viewer's time-zone, generate a descriptive summary.
 *
 * (e.g. Mon-Fri 9:00 AM - 5:00 PM)
 */
export function summarizeAvailability({
  availability,
  scheduleTimeZone,
  renderTimeZone,
  hour12,
}: {
  /**
   * The time zone we're rendering into / the time zone of the current user.
   */
  renderTimeZone: string;
  /**
   * The time zone that was used when the availability was defined.
   */
  scheduleTimeZone: string;
  /**
   * Whether or not to render in 12 vs 24 hours.
   */
  hour12: boolean;
  availability: Record<number, TimeRange[]>;
}) {
  /**
   * Convert every time range from the schedule time zone to
   * the render time zone, then create a map<'start-end', numeric-days[]>.
   */
  const now = dayjs().tz(scheduleTimeZone);

  /**
   * Convert the specified day / time combo to a 24-hour time.
   */
  const convertTime = (day: number, time: string) => {
    const [h, m, a] = time.split(/[:\s]/);
    const ampm = a?.toLowerCase() || '24';
    return now
      .set('day', day)
      .set('hour', to24Hour(h, ampm))
      .set('minute', parseInt(m, 10))
      .tz(renderTimeZone)
      .format(hour12 ? 'h:mma' : 'HH:mm');
  };

  const days = Object.keys(availability)
    .map((x) => parseInt(x, 10))
    .reduce<Record<string, number[]>>((acc, day) => {
      availability[day].forEach((range) => {
        const key = `${convertTime(day, range.start)} - ${convertTime(day, range.end)}`;
        const arr = acc[key] || [];
        arr.push(day);
        acc[key] = arr;
      });

      return acc;
    }, {});

  return Object.keys(days).map((k) => {
    return { days: summarizeDays(days[k]), times: k };
  });
}
